// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import jQuery from 'jquery';
import $ from 'jquery';
import * as bootstrap from "bootstrap"
import 'bootstrap-datepicker'

$(function () {
    $.ajaxSetup({
        headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')}
    });
});

window.crud_edit_row = function (div_id, url) {
    $.ajax(url, {
        data: {},
        dataType: "html",
        method: "GET",
        success: function (data) {
            $("#" + div_id).replaceWith(data);
        },
        error: function () {
            alert("Update error.");
        },
    })
}

window.crud_edit_row_submit = function (div_id, url) {
    let data = {};

    $("#" + div_id).find(":input").each(function () {
        let v = $(this).val();
        let n = $(this).attr("data-name");
        data[n] = v;
    });

    $.ajax(url, {
        data: data,
        dataType: "html",
        method: "PATCH",
        success: function (data) {
            $("#" + div_id).replaceWith(data);
        },
        error: function () {
            alert("Update error.");
        },
    })
}

window.crud_edit_row_cancel = function (div_id, url) {
    $.ajax(url, {
        data: {},
        dataType: "html",
        method: "PATCH",
        success: function (data) {
            $("#" + div_id).replaceWith(data);
        },
        error: function () {
            alert("Update error.");
        },
    })
}

window.crud_delete_row = function (div_id, url) {
    if (confirm("Are you sure?")) {
        $.ajax(url, {
            method: "DELETE",
            success: function () {
                $("#" + div_id).remove();
            },
            error: function () {
                alert("Error deleting the row.");
            },
        })
    }
}

window.crud_create_show = function (div_id, url) {
    $.ajax(url, {
        data: {},
        dataType: "html",
        method: "GET",
        success: function (data) {
            $("#" + div_id + "-new").replaceWith(data);
        },
        error: function () {
            alert("Create error.");
        },
    })
}

window.crud_create_submit = function (div_id, url) {
    let data = {};

    $("#" + div_id + "-new").find(":input").each(function () {
        let v = $(this).val();
        let n = $(this).attr("data-name");
        data[n] = v;
    });

    $.ajax(url, {
        data: data,
        dataType: "html",
        method: "POST",
        success: function (data, status, xhr) {
            if (xhr.status === 201) {
                $("#" + div_id + "-new").addClass("d-none");
                $("#" + div_id + "-table").prepend(data);
            } else {
                $("#" + div_id + "-new").replaceWith(data);
            }
        },
        error: function () {
            alert("Create error.");
        },
    })
}

window.crud_create_cancel = function (div_id) {
    $("#" + div_id + "-new").addClass("d-none");
}

window.account_type_select = function (element) {
    let selected = element.value;
    let type = selected.split('/')[0];
    let oauth = selected.endsWith('/oauth');

    if (selected == "") {
        $('#c-description').replaceWith("<div id='c-description'></div>");
        $("#c-inputs").replaceWith("<div id='c-inputs'></div>");
        $('#form_submit').addClass("d-none");
        return;
    }

    $.ajax("/accounts/new?key=" + selected, {
        dataType: "json",
        method: "GET",
        success: function (data, status, xhr) {
            console.log(data)

            if (!oauth) {
                $('#c-description').replaceWith("<div id='c-description'>" +
                    "<h3>" + data.description + "</h3>" +
                    "<code>" + data.intro.replace("\n", "<br />", "g") + "</code>" +
                    "</div>");

                var inputs = "<div id='c-inputs'>";

                $.each(data.fields, function (k, v) {
                    inputs += "<div>";
                    inputs += "<div class='row md-3'>";
                    inputs += "<div class='input-group'>";

                    inputs += "<div class='input-group-text'>";
                    inputs += "<i class='fa fa-id-card'></i>";
                    inputs += "</div>";

                    inputs += "<input type='text' name='" + k + "' id='" + k + "' placeholder='" + v + "' class='form-control' />"

                    inputs += "</div>";
                    inputs += "</div>";
                    inputs += "</div>";
                });

                inputs += "</div>";
                $("#c-inputs").replaceWith(inputs);

            } else {
                $('#c-description').replaceWith("<div id='c-description'>" +
                    "<h3>" + data.description + "</h3>" +
                    "</div>");
                $("#c-inputs").replaceWith("<div id='c-inputs'></div>");
            }

            $('#form_submit').removeClass("d-none");
        },
        error: function () {
            alert("Communication error.");
        },
    })
}

window.account_show = function (path, id) {
    var since = $('#since')[0].value;
    var until = $('#until')[0].value;
    var currencyItem = $('#currency option:selected');
    if (currencyItem.length > 0) {
        var currency = $('#currency option:selected')[0].value;
        window.location.href = path + encodeURIComponent(id) + "?since=" + since + "&until=" + until + "&currency=" + currency;
    } else {
        window.location.href = path + encodeURIComponent(id) + "?since=" + since + "&until=" + until;
    }
}

window.toggle_show = function (selector) {
    $("#" + selector).toggle();
}

window.datepicker = function (s) {
    $(s).datepicker({
        format: 'yyyy-mm-dd',
    });
}

$(document).on("turbo:load", () => {
    datepicker('#since');
    datepicker('#until');


    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })
})

window.addressnames_get = function (url) {
    let coin = $('#q-coin').val();
    let address = $("#q-address").val();

    $.ajax(url, {
        data: {
            chain: coin,
            address: address
        },
        dataType: "json",
        method: "GET",
        success: function (data) {
            $("#q-result-name").text(data["name"]);
            $("#q-result-category").text(data["category"]);
            $("#q-result").removeClass("d-none");
        },
        error: function () {
            $("#q-result").addClass("d-none");
            alert("Error getting data");
        },
    })
}

window.riskreport_get = function (url) {
    let coin = $('#q-coin').val();
    let address = $("#q-address").val();

    $.ajax(url, {
        data: {
            chain: coin,
            address: address
        },
        dataType: "json",
        method: "GET",
        success: function (data) {
            let qa = "";
            let qc = "";
            $.each(data["details"]["own_categories"], function (i, v) {
                qa += "<tr>";
                qa += "<td>" + v["address"] + "</td>";
                qa += "<td>" + v["name"] + "</td>";
                qa += "<td>" + v["category_name"] + "</td>";
                qa += "<td>" + v["risk"] + "</td>";
                qa += "</tr>";
            });
            $.each(data["details"]["source_of_funds_categories"], function (i, v) {
                qc += "<tr>";
                qc += "<td>" + v["address"] + "</td>";
                qc += "<td>" + v["name"] + "</td>";
                qc += "<td>" + v["category_name"] + "</td>";
                qc += "<td>" + v["risk"] + "</td>";
                qc += "</tr>";
            });
            $("#q-rep-address").html(qa);
            $("#q-rep-cluster").html(qc);
            $("#q-rep-addr").html(data["address"]);
            $("#q-rep-chain").html(data["chain"]);
            $("#q-rep-risk").html(data["risk"]);
            $("#q-rep-caseid").html(data["case_id"]);
            $("#q-result").removeClass("d-none");
        },
        error: function () {
            $("#q-result").addClass("d-none");
            alert("Error getting data");
        },
    })
}
